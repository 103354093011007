<template>
   <v-app>
      <v-app-bar app color="#FFFFFF">
        <v-app-bar-title>
          <v-img width="94" contain :src="require('@/assets/logo.svg')"/>
          </v-app-bar-title>
      </v-app-bar>
      <v-main >
        <v-container color="transparent">
          <v-row class="pt-10">
            <v-spacer/>
            <v-col>
          <v-card width="614" flat>
            <v-card-text class="text-center">
                  <v-img class="mx-auto" width="196" contain  :src="require('@/assets/notauthorize.svg')"></v-img>
              <div class="pt-5 text-h4 font-weight-bold natural--text text--darken-4">
คุณไม่ได้รับสิทธิ์เข้าถึง
              </div>
              <div class="pt-5 pr-10 pl-10 mr-5 ml-5">
              </div>
              <v-btn height="54" width="195" color="primary" @click="$auth.logout()"><span class="text-title-2">ย้อนกลับ</span></v-btn>
            </v-card-text>
          </v-card>
            </v-col>
            <v-spacer/>
          </v-row>
        </v-container>
    </v-main>
   </v-app>
</template>

<script>

export default {

}
</script>

<style>

</style>
